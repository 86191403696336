<template>
  <p class="clearfix mb-0">
    <span
      class="float-md-left d-block d-md-inline-block mt-25 text-secondary font-small-3 ml-25"
    >
      {{ adresses.web_title }}

      <span class="d-none d-sm-inline-block text-secondary font-small-3">{{
        adresses.footer_desc
      }}</span>
      <!-- <span class="ml-1 font-small-3 text-muted">ver3.2.2</span> -->
    </span>

    <!-- <span class="float-md-right d-none d-md-block"
      >Hand-crafted &amp; Made with
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span> -->
    <b-link
      class="float-md-right d-none d-md-block ml-2 text-secondary font-small-3"
      :href="adresses.faq"
      target="_blank"
    >
      {{ lang("t_frequentlyAskedQuestions") }}
    </b-link>
    <b-link
      class="float-md-right d-none d-md-block ml-2 text-secondary font-small-3"
      :href="adresses.help_center"
      target="_blank"
    >
      {{ lang("t_helpCenter") }}
    </b-link>
    <b-link
      class="float-md-right d-none d-md-block text-secondary font-small-3"
      :href="adresses.contact_us"
      target="_blank"
      >{{ lang("t_contactUs") }}
    </b-link>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      adresses: {
        faq: globalThis.env.FAQ ?? '//www.voyce.com.tr',
        help_center: globalThis.env.HELP_CENTER ?? '//www.voyce.com.tr',
        contact_us: globalThis.env.CONTACT_US ?? '//www.voyce.com.tr',
        footer_desc: globalThis.env.LOGIN_FOOTER_DESC,
        web_title: globalThis.env.LOGIN_FOOTER_WEB_TITLE,
      }
    }
  },


}
</script>
