<template>
  <b-row
    align-v="center"
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >
    <!-- Content Left -->
    <!-- <h2
    <b-col class="content-header-left mt-0 pt-0" cols="12" md="9">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">

            class="content-header-title float-left pr-1 mb-0 ml-1"
            style="opacity: 0.8"
          >
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
           <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
    -->

    <!-- Content Right -->
    <!-- <b-col
      class="content-header-right text-md-right d-md-block d-none mb-1"
      cols="3"
    >
      <div class="w-100">
        <b-row align-h="between">
          <b-col> </b-col>
          <b-col>
            <b-button
              @click="on_panel_click('softphone')"
              :style="{ opacity: phone_panel_toggle ? 0.5 : 1 }"
              :variant="phone_panel_toggle ? 'secondary' : 'flat-secondary'"
              class="btn-icon rounded-circle"
            >
              <feather-icon size="20" icon="PhoneIcon" />
            </b-button>
            <b-button
              @click="on_panel_click('chat')"
              :style="{ opacity: chat_panel_toggle ? 0.5 : 1 }"
              :variant="chat_panel_toggle ? 'secondary' : 'flat-secondary'"
              class="btn-icon rounded-circle ml-50"
            >
              <feather-icon size="20" icon="MessageSquareIcon" />
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-col> -->
  </b-row>
</template>

<script>
import { BButtonGroup, BBadge, BBreadcrumb, BBreadcrumbItem, BRow, BCol, BButton, } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'



export default {
  data() {
    return {
      content_type: '',

      chat_panel_toggle: false,
      phone_panel_toggle: true
    };
  },
  props: {
    change_panel: {
      type: Function,
      default: null,
    },
  },

  methods: {
    on_panel_click(type) {

      this.change_panel(type);
      if (type == 'softphone') {
        this.chat_panel_toggle = false;

        if (this.phone_panel_toggle) {
          this.phone_panel_toggle = false;
        }
        else {
          this.phone_panel_toggle = true;
        }
      }
      else {
        this.phone_panel_toggle = false;
        if (this.chat_panel_toggle) {
          this.chat_panel_toggle = false;
        }
        else {
          this.chat_panel_toggle = true;
        }
      }
    },

  }, directives: {
    Ripple,
  },
  components: {
    BBadge,
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BButton,
    BButtonGroup,
  },

  mounted: function () {


  }
}
</script>

