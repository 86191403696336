<template>
  <div>
    <b-card :title="lang('t_changePassword')">
      <span class="font-small-3 text-muted">
        {{ lang("t_PasswordCondition") }}
      </span>
      <b-form-group class="mt-1 w-100">
        <div class="d-flex justify-content-between">
          <label for="login-password">{{ lang("t_currentPassword") }}</label>
        </div>

        <b-input-group class="input-group-merge">
          <b-form-input
            id="current_password"
            v-model="currentData.current_password"
            name="securtiy-current-password"
            class="form-control-merge"
            :type="passwordFieldType"
            :placeholder="lang('t_password')"
          />
          <!-- <b-input-group-append is-text>
            <feather-icon
              class="cursor-pointer"
              :icon="passwordToggleIcon"
              @click="togglePasswordVisibility"
            />
          </b-input-group-append> -->
        </b-input-group>
      </b-form-group>
      <b-form-group class="mt-1 w-100">
        <div class="d-flex justify-content-between">
          <label for="login-password">{{ lang("t_newPassword") }}</label>
        </div>

        <b-input-group class="input-group-merge">
          <b-form-input
            id="new_password"
            v-model="currentData.new_password"
            name="securtiy-new-password"
            class="form-control-merge"
            :type="passwordFieldType"
            :placeholder="lang('t_password')"
          />
          <!-- <b-input-group-append is-text>
            <feather-icon
              class="cursor-pointer"
              :icon="passwordToggleIcon"
              @click="togglePasswordVisibility"
            />
          </b-input-group-append> -->
        </b-input-group>
      </b-form-group>
      <b-form-group class="mt-1 w-100">
        <div class="d-flex justify-content-between">
          <label for="login-password">{{ lang("t_confirmPassword") }}</label>
        </div>

        <b-input-group class="input-group-merge">
          <b-form-input
            id="confirm_password"
            v-model="currentData.confirm_password"
            name="securtiy-confirm-password"
            class="form-control-merge"
            :type="passwordFieldType"
            :placeholder="lang('t_password')"
          />
          <!-- <b-input-group-append is-text>
            <feather-icon
              class="cursor-pointer"
              :icon="passwordToggleIcon"
              @click="togglePasswordVisibility"
            />
          </b-input-group-append> -->
        </b-input-group>
      </b-form-group>
      <b-button
        @click="setPassword"
        class="mt-1 float-right"
        variant="primary"
        >{{ lang("t_save") }}</b-button
      >
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BAvatar, BImg, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BButton
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BButton,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup, BFormInput,
    BCard,
    BTable,
    BAvatar,
    BImg,
  },
  mixins: [togglePasswordVisibility],

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      passwordFieldType: 'password',
      password: '',
      currentData: {
        current_password: '',
        new_password: '',
        confirm_password: '',
      },
      tableData: [
        {
          ip: '5.255.110.15',
          device: 'Windows',
          browser: 'Chrome',
          date: '18/11/2023 18:11:23',

        },
        {
          ip: '5.255.110.15',
          device: 'macOS',
          browser: 'Chrome',
          date: '18/10/2023 09:11:23',

        },

      ],
      fields: [
        { key: 'ip', label: 'IP' },
        { key: 'device', label: 'DEVICE' },
        { key: 'browser', label: 'BROWSER' },
        { key: 'date', label: 'DATE' },

      ],
    }
  },
  methods: {
    isPasswordValid(password) {
      return password.length >= 6 && /[a-zA-Z]/.test(password) && /[0-9]/.test(password);
    },

    showAlert(title, text) {
      this.$swal({
        title: globalThis._lang(title),
        text: globalThis._lang(text),
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    async setPassword() {
      const { current_password, new_password, confirm_password } = this.currentData;

      if (!current_password || !new_password || !confirm_password) {
        this.showAlert("t_warning", "t_pleaseDoNotLeaveAnyBlankFields");
        return;
      }

      if (current_password != globalThis.user.password) {
        this.showAlert("t_warning", "t_currentPasswordDoesNotMatch");
        return;
      }

      if (new_password != confirm_password) {
        this.showAlert("t_warning", "t_confirmPasswordDoesNotMatch");
        return;
      }

      if (current_password == new_password) {
        this.showAlert("t_warning", "t_currentPasswordAndNewPasswordCantSame");
        return;
      }

      if (!this.isPasswordValid(new_password)) {
        this.showAlert("t_warning", "t_PasswordCondition");
        return;
      }

      var response = (
        await this.$http_in.put(
          `agent/v1/SetPassword`, {
          ...this.currentData,
          identifier: globalThis.user.identifier
        })
      ).data;

      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        globalThis.v_navbar.modal_change_password = false;
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

    }
  }

}
</script>

<style lang="scss" scoped>
@import "@core/scss/base/bootstrap-extended/include";
@import "@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
