<template>
  <div class="customizer d-none d-md-block">
    <!-- Toggler -->
    <!-- <b-link
      @click="set_panel_state('softphone')"
      :style="{ opacity: phone_panel_toggle == true ? 1 : 0.6 }"
      class="customizer-toggle d-flex align-items-center justify-content-center mb-3"
    >
      <feather-icon icon="PhoneIcon" size="15" />
    </b-link>
    <b-link
      v-if="other_perms.includes('chat')"
      variant="primary"
      @click="set_panel_state('chat')"
      :style="{ opacity: chat_panel_toggle == true ? 1 : 0.6 }"
      class="customizer-toggle d-flex align-items-center justify-content-center mt-3"
    >
      <feather-icon icon="MessageSquareIcon" size="15" />
    </b-link> -->

    <!-- /Toggler -->

    <!-- Header -->
  </div>
</template>

<script>
import {
  BButton, BLink, BFormRadioGroup, BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useAppCustomizer from './useAppCustomizer'

export default {
  components: {
    // BSV
    BButton,
    BLink,
    BFormRadioGroup,
    BFormCheckbox,
    BFormGroup,

    // 3rd party
    vSelect,
    VuePerfectScrollbar,

  },
  data() {
    return {
      other_perms: [],
      chat_panel_toggle: false,
      phone_panel_toggle: true
    };
  },
  methods: {
    set_panel_state(state) {
      if (state == 'softphone') {
        this.chat_panel_toggle = false;

        if (this.phone_panel_toggle) {
          this.phone_panel_toggle = false;
        }
        else {
          this.phone_panel_toggle = true;
        }
      }
      else {
        this.phone_panel_toggle = false;
        if (this.chat_panel_toggle) {
          this.chat_panel_toggle = false;
        }
        else {
          this.chat_panel_toggle = true;
        }
      }
      globalThis.LayoutContentRendererDefault.change_panel(state);

    }

  },
  mounted: function () {
    globalThis.AppCustomizer = this;
    this.other_perms = globalThis.permissions["other"] || [];
  },
  setup() {
    const {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isCustomizerOpen,

      // Skin
      skin,
      skinOptions,

      // Content Width
      contentWidth,
      contentWidthOptions,

      // RTL
      isRTL,

      // routerTransition
      routerTransition,
      routerTransitionOptions,

      // Layout Type
      layoutType,
      layoutTypeOptions,

      // NavMenu Hidden
      isNavMenuHidden,

      // Navbar
      navbarColors,
      navbarTypes,
      navbarBackgroundColor,
      navbarType,

      // Footer
      footerTypes,
      footerType,
    } = useAppCustomizer()

    if (layoutType.value === 'horizontal') {
      // Remove semi-dark skin option in horizontal layout
      const skinSemiDarkIndex = skinOptions.findIndex(s => s.value === 'semi-dark')
      delete skinOptions[skinSemiDarkIndex]

      // Remove menu hidden radio in horizontal layout => As we already have switch for it
      const menuHiddneIndex = navbarTypes.findIndex(t => t.value === 'hidden')
      delete navbarTypes[menuHiddneIndex]
    }

    // Perfect Scrollbar
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isCustomizerOpen,

      // Skin
      skin,
      skinOptions,

      // Content Width
      contentWidth,
      contentWidthOptions,

      // RTL
      isRTL,

      // routerTransition
      routerTransition,
      routerTransitionOptions,

      // Layout Type
      layoutType,
      layoutTypeOptions,

      // NavMenu Hidden
      isNavMenuHidden,

      // Navbar
      navbarColors,
      navbarTypes,
      navbarBackgroundColor,
      navbarType,

      // Footer
      footerTypes,
      footerType,

      // Perfect Scrollbar
      perfectScrollbarSettings,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
@import "@core/scss/base/bootstrap-extended/include";
@import "@core/scss/base/components/variables-dark";

.customizer-section {
  padding: 1.5rem;
  border-bottom: 1px solid #ebe9f1;

  .dark-layout & {
    border-color: $theme-dark-border-color;
  }

  #skin-radio-group ::v-deep {
    .custom-control-inline {
      margin-right: 0.7rem;
    }
  }

  .form-group {
    margin-bottom: 1.5rem;
    &:last-of-type {
      margin-bottom: 0;
    }
    ::v-deep legend {
      font-weight: 500;
    }
  }
}

.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.ps-customizer-area {
  height: calc(100% - 83px);
}
</style>
